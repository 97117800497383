<template>
  <v-container class="bg-glass-effect px-3 pt-10" fluid>
    <Back v-if="!isLoading"/>
    <v-row v-if="!isLoading" class="px-3">
      <v-col class="pt-7 pb-0" cols="12">
        <h1 class="principal-color">Sobre</h1>
      </v-col>
      <v-col>
        <div v-html="template"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Back from '@/components/covid/back-arrow/index'
import EuProtegidoService from "@/services/euprotegido/EuProtegidoService";
import {mapMutations} from "vuex";
export default {
  name: "miniMenuCovid",
  components: {
    Back,
  },
  data: () => ({
    isLoading: true,
    template: ''
  }),
  beforeCreate() {
    this._euProtegidoService = new EuProtegidoService();
  },
  async created () {
    this.loading(true)
    await this._euProtegidoService.GetTemplateAboutApp({version:this.getBuildVersion()})
        .then(response => {
          this.template = response.data
        }).catch(error => {
          this.template = '<p>Não foi possível carregar a página Sobre.</p>'
        }).finally(()=>{
          this.isLoading = false
          this.loading(false)
        })
  },
  methods: {
    ...mapMutations(["loading"]),
    getBuildVersion(){
      return process.env.VUE_APP_BUILD_VERSION
    }
  }
}
</script>

<style scoped>

/*background effect blur*/
.bg-glass-effect{
  background: rgba(255,255,255,1.0) !important;
  height: 100vh;
}

.principal-color {
  color: #4A499B;
}

</style>


